import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import * as validationManager from '../Utils/ValidationManager';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import Guid from 'guid';
import str2ab from 'string-to-arraybuffer';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import converter from 'json-2-csv';
import FileSaver from 'file-saver';
import Draggable from 'react-draggable';
import * as linker from '../Utils/UniversalLinker';
import * as tokenModels from '../Utils/TokenModels';
import {
  getCookie,
  isToken,
  setPolicyBoolean,
  getCurrentTimeString,
  checkPolicy,
  checkIfHome,
  handleEnhancedNav,
  detokenizeField,
  downloadCSVFormatted,
  formatValue,
} from '../Utils/HelperFunctions';
import LOADocument from './LOADocument';
import Account from './Account';
import * as PermissionManager from '../Utils/PermissionManager';
import * as globals from '../../Globals/Variables';
import * as DropdownModels from './DropdownModels';
import * as AccountModels from './AccountModels';
import * as TokenModels from '../Utils/TokenModels';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tooltip from '@mui/material/Tooltip';
import ExportPreference from './ExportPreference';
import { confirmAlert } from 'react-confirm-alert';
const FontAwesome = require('react-fontawesome');
const Spinner = require('react-spinkit');
const queryString = require('query-string');
var debounce = require('lodash/debounce');
let excludeCols = ['PrimarySigner', 'LoaCreateDate', 'LoaStatus', 'EnrollMethod', 'LoaChangeDate', 'LOASID', 'SubProcessor'];
const quickfilterModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    // position: "static",
    background: 'none',
    top: '5%',
    left: '13%',
    height: '80%',
    width: '75%',
  },
};
const largeQuickfilterModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    // position: "static",
    top: '3%',
    left: '20%',
    background: 'none',
    height: '80%',
    width: '60%',
    verticalAlign: 'middle',
  },
};
const dateQuickfilterModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '9',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    // position: "static",
    background: 'none',
    top: '8%',
    left: '30%',
    height: '50%',
    width: '40%',
  },
};

const modalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    height: '80%',
    width: '95%',
  },
};
const smallModalStyle = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    height: '60%',
    width: '40%',
  },
};

const styleOverrides = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    // bottom: "unset",
    overflow: 'visible',
    padding: 0,
    border: 'none',
    borderRadius: 0,
    position: 'static',
    background: 'none',
    width: '70%',
    height: '85%',

    // top: '25%',
    // pointerEvents: "none"
  },
};

function getINITIAL_STATE(that) {
  let policies = that.props.user.firmPolicies;
  return {
    exportPreferences: { exportRows: 'all', exportColumns: 'page', exportColumnNames: 'page' },
    customNoData: false,
    tokenFilter: checkPolicy(policies[globals.ENROLLMENT_ANONYMIZED]) ? false : true,
    searchText: '',
    searchTokens: [],
    searchRelationshipIdText: '',
    searchRelationshipIdTokens: [],
    searchAdvisorIdText: '',
    searchAdvisorIdTokens: [],
    searchAccountSIDText: '',
    searchInvestorIdText: '',
    searchClientIdText: '',
    searchInvestmentIdText: '',
    searchPartnerIdText: '',
    searchTeamIdText: '',
    searchCustom1Text: '',
    searchCustom2Text: '',
    searchCustom3Text: '',
    searchCustom4Text: '',
    data: [],
    selectedAccountId: null,
    selectedAccountData: null,
    toEnroll: false,
    previewModalOpen: false,
    pagesize: 20,
    page: 0,
    rowCount: 0,
    PIIClicked: [],
    filter: `&$filter=(contains(AccountName,''))`,
    orderby: '&$orderby=AccountCreateDate desc',
    selectedIndex: 0,
    quickfilters: [],
    quickfilterValues: {
      AccountStatus: [],
      AccountSID: [],
      EnrolledDate: [],
      Custodian: [],
      Signer: [],
      RelationshipId: [],
      AdvisorId: [],
      InvestmentId: [],
      ClientID: [],
      InvestorID: [],
      PartnerID: [],
      TeamID: [],
      Custom1: [],
      Custom2: [],
      Custom3: [],
      Custom4: [],
    },
    quickfilterModalValues: {
      AccountStatus: [],
      AccountSID: [],
      EnrolledDate: [moment(Date.now()), moment(Date.now())],
      Custodian: [],
      Signer: [],
      RelationshipId: [],
      AdvisorId: [],
      InvestorID: [],
      ClientID: [],
      InvestmentID: [],
      PartnerID: [],
      TeamID: [],
      Custom1: [],
      Custom2: [],
      Custom3: [],
      Custom4: [],
    },
    quickfilterOptions: {
      AccountStatus: [],
      AccountSID: [],
      EnrolledDate: [],
      Custodian: [],
      Signer: [],
      RelationshipId: [],
      AdvisorId: [],
      InvestmentId: [],
      ClientID: [],
      InvestorID: [],
      PartnerID: [],
      TeamID: [],
      Custom1: [],
      Custom2: [],
      Custom3: [],
      Custom4: [],
    },
    dateFromSelected: false,
    dateToSelected: false,
    dateSaved: false,
    dateWarning: false,
    custodianQuickfilterSearch: '',
    signerQuickfilterSearch: [],
    accountFilter: '',
    exactAccountName: false,
    columns: [
      {
        Header: () => {
          return <div className="select-div">Select</div>;
        },
        accessor: 'Checkboxes',
        Cell: that.buttonCell,
        sortable: false,
        width: 75,
      },
      {
        accessor: 'ExternalAccountID',
        Header: 'AccountID',
        Cell: that.textCell,
        sortable: false,
      },
      {
        accessor: 'STATUS',
        Header: 'Status',
        Cell: that.textCell,
        sortable: true,
      },
      {
        accessor: 'signerName',
        Header: 'Signer Name',
        Cell: that.textCell,
        sortable: true,
      },
      {
        accessor: 'signerEmail',
        Header: 'Signer Email',
        Cell: that.textCell,
        sortable: true,
      },
      {
        accessor: 'CustodianName',
        Header: 'Custodian Name',
        Cell: that.textCell,
        sortable: true,
      },
      {
        accessor: 'ApprovedDate',
        Header: 'Approved Date',
        Cell: that.textCell,
        datatype: { value: 'date' },
        format: { value: 'MM/DD/YYYY hh:mm A' },
      },
    ],
    loading: true,
    key: new Date().getTime(),
    enrollPrefixLabel: that.props.user.firmPolicies[globals.UI_ENROLLMENT_ENROLLPREFIXLABEL].Value,
    enrollAccountTaxEntityShow: checkPolicy(policies[globals.UI_ENROLLMENT_TAXENTITY_SHOW]),
    enhancedNavigation: checkPolicy(policies[globals.UI_ENROLLMENT_ENHANCED_NAVIGATION]),
    enrollAccountsNewButton: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_ACCOUNTS_NEW_BUTTON_LABEL]),
    enrollHomeTitle: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_HOME_TITLE]),
    enrollHomeSection: checkPolicy(policies[globals.UI_ENROLLMENT_ENROLL_HOME_REDIRECT_SECTION]),
  };
}

let EnrollmentDownloadable = false;

export default class AkoyaAccount extends Component {
  constructor(props) {
    super(props);
    this.state = getINITIAL_STATE(this);
    let { columns, enrollment_anonymized } = this.state;
    // if (props.user.firmPolicies[globals.UI_ENROLLMENT_GRID_CLIENT].Value === 'true') {
    //   columns.push({
    //     accessor: 'ClientName',
    //     Header: 'Client',
    //     Cell: this.textCell,
    //     sortable: false,
    //     width: 150,
    //   });
    // }

    // if (props.user.firmPolicies[globals.UI_ENROLLMENT_GRID_ADVISOR].Value === 'true') {
    //   columns.push({
    //     accessor: 'AdvisorName',
    //     Header: 'Advisor',
    //     Cell: this.textCell,
    //     sortable: false,
    //     width: 150,
    //   });
    // }

    // OTHER Fields that shall be displayed in the grid if <field>.Edit policy = true
    columns.push(...this.getPolicyBasedColumns());
    this.setState({ columns });
  }

  getPolicyBasedColumns = () => {
    let columns = [];
    let policies = this.props.user.firmPolicies;
    let checkPolicies = [
      //{ show: 'UI_ENROLLMENT_RELATIONSHIPID_SHOW', label: 'UI_ENROLLMENT_RELATIONSHIPID_LABEL', field: 'RelationshipID' },
      //{ show: 'UI_ENROLLMENT_ADVISORID_SHOW', label: 'UI_ENROLLMENT_ADVISORID_LABEL', field: 'AdvisorID' },
      // { show: 'UI_ENROLLMENT_PARTNERID_SHOW', label: 'UI_ENROLLMENT_PARTNERID_LABEL', field: 'PartnerID' },
      // { show: 'UI_ENROLLMENT_TEAMID_SHOW', label: 'UI_ENROLLMENT_TEAMID_LABEL', field: 'TeamID' },
      // { show: 'UI_ENROLLMENT_CLIENTID_SHOW', label: 'UI_ENROLLMENT_CLIENTID_LABEL', field: 'ClientID' },
      // { show: 'UI_ENROLLMENT_INVESTMENTID_SHOW', label: 'UI_ENROLLMENT_INVESTMENTID_LABEL', field: 'InvestmentID' },
      // { show: 'UI_ENROLLMENT_INVESTORID_SHOW', label: 'UI_ENROLLMENT_ALTS_INVESTORID_LBL', field: 'InvestorID' },
      // { show: 'UI_ENROLLMENT_ACCOUNTCUSTOM1_SHOW', label: 'UI_ENROLLMENT_ACCOUNTCUSTOM1_LABEL', field: 'Custom1' },
      // { show: 'UI_ENROLLMENT_ACCOUNTCUSTOM2_SHOW', label: 'UI_ENROLLMENT_ACCOUNTCUSTOM2_LABEL', field: 'Custom2' },
      // { show: 'UI_ENROLLMENT_ACCOUNTCUSTOM3_SHOW', label: 'UI_ENROLLMENT_ACCOUNTCUSTOM3_LABEL', field: 'Custom3' },
      // { show: 'UI_ENROLLMENT_ACCOUNTCUSTOM4_SHOW', label: 'UI_ENROLLMENT_ACCOUNTCUSTOM4_LABEL', field: 'Custom4' },
    ];
    for (let x in checkPolicies) {
      if (checkPolicy(policies[globals[checkPolicies[x].show]])) {
        columns.push({
          accessor: checkPolicies[x].field,
          Header: policies[globals[checkPolicies[x].label]] ? policies[globals[checkPolicies[x].label]].Value : '',
          Cell: this.textCell,
          sortable: false,
          width: 150,
        });
      }
    }

    return columns;
  };

  componentDidMount() {
    let { enrollment_anonymized } = this.state;
    if (queryString.parse(window.location.search).navigationMode && queryString.parse(window.location.search).navigationMode === 'limited') {
      document.cookie = `accounts_tokenFilter=false;path=/;${globals.SAMESITECOOKIE}`;
      document.cookie = `path=view;path=/;${globals.SAMESITECOOKIE}`;
      this.setState({ navigationMode: 'limited', tokenFilter: false });
    }

    if (enrollment_anonymized) {
      document.cookie = `accounts_tokenFilter=false;path=/;${globals.SAMESITECOOKIE}`;
      this.setState({ tokenFilter: false });
    }

    let filterObj = this.state.quickfilterModalValues;
    if (getCookie('accounts_filter') !== '' && JSON.parse(getCookie('accounts_filter'))) {
      filterObj = JSON.parse(getCookie('accounts_filter'));
      if (filterObj.EnrolledDate) {
        filterObj.EnrolledDate = [moment(filterObj.EnrolledDate[0]), moment(filterObj.EnrolledDate[1])];
      }
    }
    // PageSize
    let pagesize = this.state.pagesize;
    if (getCookie('table_pagesize') !== '' && JSON.parse(getCookie('table_pagesize'))) {
      pagesize = JSON.parse(getCookie('table_pagesize'));
    }
    // All filters selected (buttons green or not)
    let filterSelectedObj = this.state.quickfilters;
    if (getCookie('accounts_filter_selected') !== '' && JSON.parse(getCookie('accounts_filter_selected'))) {
      filterSelectedObj = JSON.parse(getCookie('accounts_filter_selected'));
      filterSelectedObj = Object.keys(filterSelectedObj).map((key) => filterSelectedObj[key]);
    }
    // Search Account Token Text in cookie
    let searchText = this.state.searchText;
    if (getCookie('accounts_searchText') !== '' && JSON.parse(getCookie('accounts_searchText'))) {
      searchText = JSON.parse(getCookie('accounts_searchText'));
    }
    // Search Relationship Token Text in cookie
    let searchRelationshipIdText = this.state.searchRelationshipIdText;
    if (getCookie('accounts_searchRelationshipIdText') !== '' && JSON.parse(getCookie('accounts_searchRelationshipIdText'))) {
      searchRelationshipIdText = JSON.parse(getCookie('accounts_searchRelationshipIdText'));
    }
    // Search Advisor Token Text in cookie
    let searchAdvisorIdText = this.state.searchAdvisorIdText;
    if (getCookie('accounts_searchAdvisorIdText') !== '' && JSON.parse(getCookie('accounts_searchAdvisorIdText'))) {
      searchAdvisorIdText = JSON.parse(getCookie('accounts_searchAdvisorIdText'));
    }
    let searchInvestmentIdText = this.state.searchInvestmentIdText;
    if (getCookie('accounts_searchInvestmentIdText') !== '' && JSON.parse(getCookie('accounts_searchInvestmentIdText'))) {
      searchInvestmentIdText = JSON.parse(getCookie('accounts_searchInvestmentIdText'));
    }
    let searchInvestorIdText = this.state.searchInvestorIdText;
    if (getCookie('accounts_searchInvestorIdText') !== '' && JSON.parse(getCookie('accounts_searchInvestorIdText'))) {
      searchInvestorIdText = JSON.parse(getCookie('accounts_searchInvestorIdText'));
    }
    let searchTeamIdText = this.state.searchTeamIdText;
    if (getCookie('accounts_searchTeamIdText') !== '' && JSON.parse(getCookie('accounts_searchTeamIdText'))) {
      searchTeamIdText = JSON.parse(getCookie('accounts_searchTeamIdText'));
    }
    let searchPartnerIdText = this.state.searchPartnerIdText;
    if (getCookie('accounts_searchPartnerIdText') !== '' && JSON.parse(getCookie('accounts_searchPartnerIdText'))) {
      searchPartnerIdText = JSON.parse(getCookie('accounts_searchPartnerIdText'));
    }
    let searchClientIdText = this.state.searchClientIdText;
    if (getCookie('accounts_searchClientIdText') !== '' && JSON.parse(getCookie('accounts_searchClientIdText'))) {
      searchClientIdText = JSON.parse(getCookie('accounts_searchClientIdText'));
    }
    let searchCustom1Text = this.state.searchCustom1Text;
    if (getCookie('accounts_searchCustom1Text') !== '' && JSON.parse(getCookie('accounts_searchCustom1Text'))) {
      searchCustom1Text = JSON.parse(getCookie('accounts_searchCustom1Text'));
    }
    let searchCustom2Text = this.state.searchCustom2Text;
    if (getCookie('accounts_searchCustom2Text') !== '' && JSON.parse(getCookie('accounts_searchCustom2Text'))) {
      searchCustom2Text = JSON.parse(getCookie('accounts_searchCustom2Text'));
    }
    let searchCustom3Text = this.state.searchCustom3Text;
    if (getCookie('accounts_searchCustom3Text') !== '' && JSON.parse(getCookie('accounts_searchCustom3Text'))) {
      searchCustom3Text = JSON.parse(getCookie('accounts_searchCustom3Text'));
    }
    let searchCustom4Text = this.state.searchCustom4Text;
    if (getCookie('accounts_searchCustom4Text') !== '' && JSON.parse(getCookie('accounts_searchCustom4Text'))) {
      searchCustom4Text = JSON.parse(getCookie('accounts_searchCustom4Text'));
    }
    // Search Advisor Token Text in cookie
    let searchAccountSIDText = this.state.searchAccountSIDText;
    if (getCookie('accounts_searchAccountSIDText') !== '' && JSON.parse(getCookie('accounts_searchAccountSIDText'))) {
      searchAccountSIDText = JSON.parse(getCookie('accounts_searchAccountSIDText'));
    }
    // Enrollment Date - Because the existance of an Enrollment Date (from and to initialized as moment(Date.now()))
    // doesn't mean it exists in the cookie and was actually set, well handle this separatley by keeping track of in this.state.dateSaved
    let enrollmentDateSelected = this.state.dateSaved;
    if (getCookie('accounts_enrollment_date_selected') !== '' && JSON.parse(getCookie('accounts_enrollment_date_selected'))) {
      enrollmentDateSelected = JSON.parse(getCookie('accounts_enrollment_date_selected'));
    }

    // For Account quickfilter -Account, and (Exact or Contains)
    let accountFilter = this.state.accountFilter;
    if (getCookie('accounts_filter_accountid') !== '' && JSON.parse(getCookie('accounts_filter_accountid'))) {
      accountFilter = JSON.parse(getCookie('accounts_filter_accountid'));
    }
    let accountTokenSearch = this.state.accountTokenSearch;
    if (getCookie('accounts_token_search') !== '' && JSON.parse(getCookie('accounts_token_search'))) {
      accountTokenSearch = JSON.parse(getCookie('accounts_token_search'));
      accountTokenSearch = Object.keys(accountTokenSearch).map((key) => accountTokenSearch[key]);
    }
    let exactAccountName = this.state.exactAccountName;
    if (getCookie('accounts_filter_exact') !== '' && JSON.parse(getCookie('accounts_filter_exact'))) {
      exactAccountName = JSON.parse(getCookie('accounts_filter_exact'));
    }
    let tokenFilter = this.state.tokenFilter;
    if (getCookie('accounts_tokenFilter') !== '') {
      tokenFilter = JSON.parse(getCookie('accounts_tokenFilter'));
    }

    EnrollmentDownloadable = setPolicyBoolean(this.props.user.firmPolicies[globals.UI_ENROLLMENT_DOWNLOAD_ACCOUNT_GRID]);
    this.setState(
      {
        quickfilterModalValues: filterObj,
        quickfilters: filterSelectedObj,
        dateSaved: enrollmentDateSelected,
        accountFilter: accountFilter,
        accountTokenSearch: accountTokenSearch,
        exactAccountName: exactAccountName,
        searchText,
        searchTokens: searchText ? [searchText] : [],
        searchRelationshipIdText,
        searchAdvisorIdText,
        searchInvestmentIdText,
        searchInvestorIdText,
        searchTeamIdText,
        searchPartnerIdText,
        searchClientIdText,
        searchCustom1Text,
        searchCustom2Text,
        searchCustom3Text,
        searchCustom4Text,
        searchAccountSIDText,
        tokenFilter,
        pagesize,
      },
      () => {
        if (searchRelationshipIdText) {
          this.handleTokenIdSave('RelationshipId');
        }
        if (searchAdvisorIdText) {
          this.handleTokenIdSave('AdvisorId');
        }
        if (searchInvestmentIdText) {
          this.handleTokenIdSave('InvestmentId');
        }
        if (searchInvestorIdText) {
          this.handleTokenIdSave('InvestorId');
        }
        if (searchTeamIdText) {
          this.handleTokenIdSave('TeamId');
        }
        if (searchPartnerIdText) {
          this.handleTokenIdSave('PartnerId');
        }
        if (searchClientIdText) {
          this.handleTokenIdSave('ClientId');
        }
        if (searchCustom1Text) {
          this.handleTokenIdSave('Custom1');
        }
        if (searchCustom2Text) {
          this.handleTokenIdSave('Custom2');
        }
        if (searchCustom3Text) {
          this.handleTokenIdSave('Custom3');
        }
        if (searchCustom4Text) {
          this.handleTokenIdSave('Custom4');
        }
      }
    );

    this.getStatusOptions();

    // this.getCustodianOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.services && !this.props.myAccountsLoaded) {
      this.props.setMyAccountsLoaded(true);
      // BOBBY MILLER 2019-09-03 - Commented this out for now - This was re-executing searchAccount after it was already done.
      // this.searchAccount();
    }
  }

  refreshGrid = () => {
    this.setState({ previewModalOpen: false }, () => {
      this.searchAccount();
    });
  };

  async getStatusOptions() {
    let { user, services } = this.props;
    let statusOptions = await DropdownModels.getStatus({
      user,
      services,
    });
    for (var i = statusOptions.length - 1; i >= 0; i--) {
      if (
        statusOptions[i].Status !== 'NEW' &&
        statusOptions[i].Status !== 'ENROLLING' &&
        statusOptions[i].Status !== 'ACTIVE' &&
        statusOptions[i].Status !== 'REVIEWING' &&
        statusOptions[i].Status !== 'CLOSED' &&
        statusOptions[i].Status !== 'CANCELLED' &&
        statusOptions[i].Status !== 'REVOKED' &&
        statusOptions[i].Status !== 'HOLD'
      ) {
        statusOptions.splice(i, 1);
      }
    }
    let quickfilterModalValues = Object.assign({}, this.state.quickfilterModalValues);
    let setByCookie = quickfilterModalValues.AccountStatus && quickfilterModalValues.AccountStatus.length !== 0;
    let quickfilterValues = Object.assign({}, this.state.quickfilterValues);
    if (!setByCookie && !quickfilterModalValues.AccountID) {
      //No cookie, default these guys
      quickfilterModalValues.AccountStatus = [];
      quickfilterValues.AccountStatus = [];
    }
    statusOptions.map((opt) => {
      if (opt.Name !== 'EnrollmentComplete') {
        if (!setByCookie && !quickfilterModalValues.AccountID) {
          //No cookie, default these guys
          // Remove default to all statuses selected
          // quickfilterModalValues.AccountStatus.push(opt.Name);
          // quickfilterValues.AccountStatus.push(opt.Name);
        }
      }
      opt.name = opt.Name;
      return opt;
    });
    this.setState(
      {
        selectedQuickfilter: 'AccountStatus',
        quickfilterOptions: { ...this.state.quickfilterOptions, AccountStatus: statusOptions },
        quickfilterModalValues,
      },
      () => {
        this.onSearchTextChange({ target: { value: this.state.searchText } });
      }
    );
  }

  getCustodianOptions = debounce(async () => {
    let { user, services } = this.props;
    let { custodianQuickfilterSearch, quickfilterOptions } = this.state;
    if (custodianQuickfilterSearch.length < 3) {
      return;
    } else {
      this.setState({ custodianSearchLoading: true });
      let options = await DropdownModels.getCustodians({
        user,
        services,
        search: encodeURIComponent(custodianQuickfilterSearch),
      });
      let quickfilterModalValues = Object.assign({}, quickfilterModalValues);
      options.map((opt) => {
        opt.name = opt.Name;
        return opt;
      });
      this.setState({ quickfilterOptions: { ...quickfilterOptions, Custodian: options }, custodianSearchLoading: false });
    }
  }, 500);

  getSignerOptions = debounce(async () => {
    let { user, services, crmData } = this.props;
    let { signerQuickfilterSearch, quickfilterOptions } = this.state;
    if (signerQuickfilterSearch.length < 3) {
      return;
    } else {
      this.setState({ signerSearchLoading: true });
      let options = await DropdownModels.getSigners({
        user,
        services,
        search: encodeURIComponent(signerQuickfilterSearch),
        crmData,
      });
      let quickfilterModalValues = Object.assign({}, quickfilterModalValues);
      options.map((opt) => {
        opt.name = opt.NameAlias;
        return opt;
      });
      this.setState({ quickfilterOptions: { ...quickfilterOptions, Signer: options }, signerSearchLoading: false });
    }
  }, 500);

  textCell = (cellInfo) => {
    var even_odd_css = '';
    cellInfo.viewIndex % 2 === 0 ? (even_odd_css = `custom-grid-input even-row`) : (even_odd_css = `custom-grid-input odd-row`);
    return (
      <div
        title={this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null}
        onClick={() => {
          this.fetchAccountData(this.state.data[cellInfo.index].AccountID, cellInfo);
        }}
      >
        <input
          id={cellInfo.index + cellInfo.column.id}
          className={even_odd_css}
          key={cellInfo.index + cellInfo.column.id}
          readOnly
          value={this.state.data[cellInfo.index] ? this.state.data[cellInfo.index][cellInfo.column.id] : null}
        />
      </div>
    );
  };

  handleClick = (event, index) => {
    // We are adding menues to every row, so need to properly index the anchorEl that is attached to these components
    this.setState({ [`anchorEl${index}`]: event.currentTarget, [`open${index}`]: Boolean(event.currentTarget) });
  };
  handleClose = (index) => {
    // Use the row number to properly close the correct menu.
    this.setState({ [`anchorEl${index}`]: null, [`open${index}`]: false });
  };
  buttonCell = (cellInfo) => {
    let { data, open } = this.state;
    let disabled = data[cellInfo.index].AccountStatus === 'CANCELLED' || data[cellInfo.index].AccountStatus === 'REVOKED' || data[cellInfo.index].AccountStatus === 'CLOSED';

    return (
      <div>
        <Tooltip placement="right" title={<h3>Action menu</h3>}>
          <IconButton
            id={`actions-button${cellInfo.index}`}
            aria-controls={open ? 'customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            sx={{ ml: 6, textTransform: 'capitalize' }}
            className="powergrid-actions-icon"
            onClick={(e) => {
              this.handleClick(e, cellInfo.index);
            }}
          >
            <MoreHorizIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id={`basic-menu${cellInfo.index}`}
          anchorEl={this.state[`anchorEl${cellInfo.index}`]}
          open={this.state[`open${cellInfo.index}`]}
          onClose={(e) => {
            this.handleClose(cellInfo.index);
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <Tooltip placement="right" title={<h3>Enroll Account</h3>}>
            <MenuItem>
              <div className="div-wrapper">
                <div className="powergrid-menu-icon">
                  <FontAwesome name="fa-ticket" style={{ cursor: 'pointer' }} className="fa fa-user header-ticket fa-ticket-alt" id="fa-grid-icon" />
                </div>
                <div className="powergrid-menu-text ">Enroll Account</div>
              </div>
            </MenuItem>
          </Tooltip>
          {this.state.data[cellInfo.index]['AccountName-token'] &&
          this.state.data[cellInfo.index]['AccountName-token'].startsWith('@!!!@') &&
          !this.state.PIIClicked.includes(cellInfo.index) ? (
            <Tooltip placement="right" title={<h3>View Tokenized Information</h3>}>
              <MenuItem
                disabled={disabled}
                onClick={(e) => {
                  this.getAkoyaAccountGridPII(cellInfo);
                  this.handleClose(cellInfo.index);
                }}
              >
                <div className="div-wrapper">
                  <div className="powergrid-menu-icon">
                    <FontAwesome name="far fa-eye" style={{ cursor: 'pointer' }} id="fa-grid-icon" className="far fa-eye action-eye-icon" />
                  </div>
                  <div className="powergrid-menu-text ">Detokenize</div>
                </div>
              </MenuItem>
            </Tooltip>
          ) : null}
          {this.state.data[cellInfo.index] && this.state.data[cellInfo.index].LoaID ? (
            <Tooltip placement="right" title={<h3>View LOA</h3>}>
              <MenuItem
                disabled={disabled}
                onClick={(e) => {
                  this.getCurrentLOA(this.state.data[cellInfo.index].LoaID, cellInfo.index);
                  this.handleClose(cellInfo.index);
                }}
              >
                <div className="div-wrapper">
                  <div className="powergrid-menu-icon">
                    <FontAwesome id="fa-grid-icon-document" className="fa fa-file-text-o my-enrollments-view" style={{ cursor: 'pointer' }} name="fa-file-text-o" />
                  </div>
                  <div className="powergrid-menu-text ">View LOA</div>
                </div>
              </MenuItem>
            </Tooltip>
          ) : null}
        </Menu>
      </div>
    );
    // Old actions - keeping around in case we need to revert for any reason
    // return (
    //   <div className="buttons-div">
    //     <FontAwesome
    //       title="Raise Issue"
    //       name="fa-ticket"
    //       style={{ cursor: 'pointer' }}
    //       className="fa fa-ticket header-ticket fa-ticket-alt"
    //       id="fa-grid-icon"
    //       onClick={(e) => {
    //         linker.UniversalAddTicket('Enrollment.Account.Request', 'popup', null, null, this.state.data[cellInfo.index].AccountID, this.state.data[cellInfo.index].LoaStatus);
    //       }}
    //     />

    //     <FontAwesome
    //       title={disabled ? data[cellInfo.index].AccountStatus + ' accounts cannot be edited' : 'Edit Account'}
    //       name="fa-pencil"
    //       style={{ cursor: 'pointer' }}
    //       className={disabled ? 'fa fa-pencil fa-pencil-edit-row fa-pencil-disabled' : 'fa fa-pencil fa-pencil-edit-row'}
    //       id={disabled ? 'fa-grid-icon-disabled' : 'fa-grid-icon'}
    //       onClick={(e) => {
    //         !disabled && this.handleAccountSelect(this.state.data[cellInfo.index].AccountID, this.state.data[cellInfo.index].data);
    //       }}
    //     />

    //     {this.state.data[cellInfo.index]['AccountName-token'] &&
    //     this.state.data[cellInfo.index]['AccountName-token'].startsWith('@!!!@') &&
    //     !this.state.PIIClicked.includes(cellInfo.index) ? (
    //       <FontAwesome
    //         title="View Tokenized Information"
    //         name="far fa-eye"
    //         style={{ cursor: 'pointer' }}
    //         id="fa-grid-icon"
    //         className="far fa-eye"
    //         onClick={() => {
    //           this.getAkoyaAccountGridPII(cellInfo);
    //         }}
    //       />
    //     ) : null}

    //     {this.state.data[cellInfo.index] && this.state.data[cellInfo.index].LoaID ? (
    //       <FontAwesome
    //         title="View LOA"
    //         id="fa-grid-icon-document"
    //         className="fa fa-file-text-o my-enrollments-view"
    //         style={{ cursor: 'pointer' }}
    //         name="fa-file-text-o"
    //         onClick={(e) => {
    //           this.getCurrentLOA(this.state.data[cellInfo.index].LoaID, cellInfo.index);
    //         }}
    //       />
    //     ) : null}

    //     {!this.state.data[cellInfo.index] || !this.state.data[cellInfo.index].LoaID ? <div id="fa-grid-icon" className="my-enrollments-search" style={{ width: '16.72px' }}></div> : null}
    //     {!this.state.data[cellInfo.index]['AccountName-token'] || this.state.PIIClicked.includes(cellInfo.index) ? (
    //       <div id="fa-grid-icon" className="my-enrollments-search" style={{ width: '16.72px' }}></div>
    //     ) : null}
    //   </div>
    // );
  };

  handleQuickFilter = (name) => {
    if (name === 'clear') {
      this.setState(
        {
          quickfilters: [],
          quickfilterValues: {
            AccountStatus: [],
            AccountSID: [],
            EnrolledDate: [],
            Custodian: [],
            Signer: [],
            RelationshipId: [],
            AdvisorId: [],
            TeamId: [],
            PartnerId: [],
            InvestmentId: [],
            InvestorId: [],
            ClientId: [],
            Custom1: [],
            Custom2: [],
            Custom3: [],
            Custom4: [],
          },
          quickfilterModalValues: {
            AccountStatus: [],
            AccountSID: [],
            EnrolledDate: [moment(Date.now()), moment(Date.now())],
            Custodian: [],
            Signer: [],
            RelationshipId: [],
            AdvisorId: [],
            TeamId: [],
            PartnerId: [],
            InvestmentId: [],
            InvestorId: [],
            ClientId: [],
            Custom1: [],
            Custom2: [],
            Custom3: [],
            Custom4: [],
          },
          quickfilterOptions: {
            ...this.state.quickfilterOptions,
            Custodian: [],
            Signer: [],
            RelationshipId: [],
            AdvisorId: [],
            TeamId: [],
            PartnerId: [],
            InvestmentId: [],
            InvestorId: [],
            ClientId: [],
            Custom1: [],
            Custom2: [],
            Custom3: [],
            Custom4: [],
          },
          // dateFromSelected: false,
          // dateToSelected: false,
          custodianQuickfilterSearch: '',
          dateSaved: false,
          selectedQuickfilter: null,
          searchText: '',
          searchTokens: '',
          searchRelationshipIdText: '',
          searchRelationshipIdTokens: '',
          searchAdvisorIdText: '',
          searchAdvisorIdTokens: '',
          searchPartnerIdText: '',
          searchTeamIdText: '',
          searchClientIdText: '',
          searchInvestorIdText: '',
          searchInvestmentIdText: '',
          searchCustom1Text: '',
          searchCustom2Text: '',
          searchCustom3Text: '',
          searchCustom4Text: '',
          searchAccountSIDText: '',
          relationshipIdTokens: '',
          advisorIdTokens: '',
          partnerIdTokens: '',
          teamIdTokens: '',
          investmentIdTokens: '',
          investorIdTokens: '',
          clientIdTokens: '',
          custom1Tokens: '',
          custom2Tokens: '',
          custom3Tokens: '',
          custom4Tokens: '',
          accountFilter: '',
          showSearchLengthNote: false,
        },
        () => {
          this.handleSaveQuickfilterClick();
        }
      );
    } else {
      this.setState({ selectedQuickfilter: name, quickFilterModalOpen: true });
    }
  };

  handleQuickfilterCheckboxSelect = (name, value) => {
    let { quickfilterOptions } = this.state;
    let temp = Object.assign([], this.state.quickfilterModalValues[this.state.selectedQuickfilter]);
    if (name === 'Account') {
      if (temp.includes(name) && value === 'clear') {
        temp.splice(temp.indexOf(name), 1);
        this.setState({ quickfilterModalValues: { ...this.state.quickfilterModalValues, [this.state.selectedQuickfilter]: temp } }, () => {
          this.handleSaveQuickfilterClick();
        });
      } else if (value !== 'clear') {
        temp.push(name);
      }
    } else {
      if (temp.includes(name)) {
        temp.splice(temp.indexOf(name), 1);
      } else {
        temp.push(name);
      }
    }
    if (name === 'SelectAll' && temp.includes(name)) {
      for (let i in this.state.quickfilterOptions[this.state.selectedQuickfilter]) {
        if (!temp.includes(this.state.quickfilterOptions[this.state.selectedQuickfilter][i].name)) {
          temp.push(this.state.quickfilterOptions[this.state.selectedQuickfilter][i].name);
        }
      }
    } else if (name === 'SelectAll' && !temp.includes(name)) {
      temp = [];
    } else if (name !== 'SelectAll' && this.state.selectedQuickfilter === 'AccountStatus') {
      temp = this.checkSelectAll(temp);
    }
    if (name === 'ClearAll') {
      temp = [];
      this.setState({ quickfilterOptions: { ...quickfilterOptions, Custodian: temp }, custodianSearchLoading: false, custodianQuickfilterSearch: '' });
    }

    this.setState({ quickfilterModalValues: { ...this.state.quickfilterModalValues, [this.state.selectedQuickfilter]: temp } });
  };

  checkSelectAll = (modalValues) => {
    const { quickfilterOptions } = this.state;
    let temp = Object.assign([], modalValues);
    let hasSelectAll = false;
    for (let i in temp) {
      if (temp[i] === 'SelectAll') {
        hasSelectAll++;
      }
    }

    if (quickfilterOptions.AccountStatus.length === temp.length && !hasSelectAll) {
      temp.push('SelectAll');
    } else if (quickfilterOptions.AccountStatus.length === temp.length && hasSelectAll) {
      for (let i in temp) {
        if (temp[i] === 'SelectAll') {
          temp.splice(i, 1);
        }
      }
    }
    return temp;
  };

  setTokenFilter = () => {
    let currentValue = this.state.tokenFilter;
    this.setState({ tokenFilter: !currentValue }, () => {
      document.cookie = `accounts_tokenFilter=${JSON.stringify(!currentValue)};path=/;${globals.SAMESITECOOKIE}`;
      this.searchAccount();
    });
  };

  handleSaveQuickfilterClick = (isDate) => {
    const {
      searchTokens,
      relationshipIdTokens,
      advisorIdTokens,
      investmentIdTokens,
      teamIdTokens,
      partnerIdTokens,
      clientIdTokens,
      investorIdTokens,
      custom1Tokens,
      custom2Tokens,
      custom3Tokens,
      custom4Tokens,
    } = this.state;

    if (isDate) {
      //If save qfmodal, set values to modalvalues
      this.setState(
        {
          dateSaved: true,
        },
        () => {
          let { dateSaved } = this.state;
        }
      );
    }

    //If save qfmodal, set values to modalvalues
    this.setState(
      {
        quickfilterValues: this.state.quickfilterModalValues,
        showSearchLengthNote: false,
        // dateFromSelected: true,
        // dateToSelected: true
      },
      () => {
        let { quickfilters, quickfilterValues, dateFromSelected, dateToSelected } = this.state;
        //Handles Green vs Grey

        for (let i in quickfilterValues) {
          if (i !== 'EnrolledDate') {
            if (quickfilterValues[i] && quickfilterValues[i].length > 0 && quickfilters.indexOf(i) === -1) {
              quickfilters.push(i);
            } else if (quickfilters.indexOf(i) !== -1 && quickfilterValues[i] && quickfilterValues[i].length === 0) {
              quickfilters.splice(quickfilters.indexOf(i), 1);
            } else if (quickfilters.indexOf(i) !== -1 && quickfilterValues[i] === '') {
              quickfilters.splice(quickfilters.indexOf(i), 1);
            }
          } else {
            //For Date
            // if(quickfilterValues[i] && quickfilterValues[i][0] === moment(new Date()).format().substring(0,10)) {
            //   quickfilterValues[i] = [moment(new Date()).format().substring(0,10), moment(new Date()).format().substring(0,10)];
            //   if(quickfilters.includes(i)) {quickfilters.splice(quickfilters.indexOf(i),1)}
            // } else if(quickfilterValues[i] && quickfilterValues[i].length > 0 && quickfilters.indexOf(i) === -1) {
            //   quickfilters.push(i);
            // }
            dateFromSelected = true;
            dateToSelected = true;
            if (dateFromSelected && dateToSelected && !quickfilters.includes(i) && this.state.dateSaved) {
              quickfilters.push(i);
            } else if ((!dateFromSelected || !dateToSelected) && quickfilters.includes(i)) {
              quickfilters.splice(quickfilters.indexOf(i), 1);
            }
          }
        }
        this.setState({ quickfilters });

        let wholeString = ``;
        let filter = '';
        let val, condition;

        for (let i in quickfilterValues) {
          let localString = '';
          let list = '';
          for (let j in quickfilterValues[i]) {
            let query;
            let searchBy = i;

            if (
              (quickfilters.includes(i) || i === 'AccountID') &&
              i !== 'RelationshipId' &&
              i !== 'AdvisorId' &&
              i !== 'InvestmentId' &&
              i !== 'InvestorId' &&
              i !== 'PartnerId' &&
              i !== 'TeamId' &&
              i !== 'ClientId' &&
              i !== 'Custom1' &&
              i !== 'Custom2' &&
              i !== 'Custom3' &&
              i !== 'Custom4'
            ) {
              if (i === 'AccountID') {
                this.setState({ quickfilters: [] });
              }
              isDate = i === 'EnrolledDate';
              let isValid = moment(quickfilterValues[i][j]).isValid();
              val = !Guid.isGuid(quickfilterValues[i][j]) ? `'${quickfilterValues[i][j]}'` : quickfilterValues[i][j];
              val = isDate ? moment(quickfilterValues[i][j]).startOf('day').format('YYYY-MM-DDTHH:mm:ss') + 'Z' : val;

              if (i === 'AccountSID') {
                val = val.replace("'", '');
                val = val.replace("'", '');
                val = parseInt(val);
              } else {
                val = val.replace(',', '%2C');
                val = val.replace("'", '%27');
                val = val.replace('&', '%26');
              }

              condition = isDate ? 'le' : 'eq';

              if (dateFromSelected && dateToSelected && i === 'EnrolledDate' && isValid) {
                let dateTo = quickfilterValues[i][parseInt(j) + 1] ? quickfilterValues[i][parseInt(j) + 1] : Date.now();
                dateTo = !Guid.isGuid(quickfilterValues[i][parseInt(j) + 1]) ? `'${quickfilterValues[i][parseInt(j) + 1]}'` : quickfilterValues[i][parseInt(j) + 1];
                dateTo =
                  moment(quickfilterValues[i][parseInt(j) + 1])
                    .endOf('day')
                    .format('YYYY-MM-DDTHH:mm:ss') + 'Z';
                query = `AccountCreateDate ge ${val} and AccountCreateDate le ${dateTo}`;
              }

              if (i === 'Custodian') {
                searchBy = 'CustodianName';
              }

              if (i === 'Account') {
                for (let k in this.state.accountTokenSearch) {
                  if (list.length === 0) {
                    list = `'${this.state.accountTokenSearch[k].Token}'`;
                  } else {
                    list = list + `, '${this.state.accountTokenSearch[k].Token}'`;
                  }
                }
                localString = `AccountName in (${list})`;
                break;
              } else if (i === 'Signer') {
                localString = (localString ? `${localString} or ` : '') + `Contains(Signers, ${val})`;
              } else {
                if (list.length === 0) {
                  if (i === 'AccountID') {
                    list = `'${val}'`;
                  } else {
                    list = `${val}`;
                  }
                } else {
                  if (i === 'AccountID') {
                    list = list + `, '${val}'`;
                  } else {
                    list = list + `, ${val}`;
                  }
                }
                if (query && (!localString || localString.length === 0)) {
                  localString = query;
                } else if (query) {
                  localString = localString + ` or ${query}`;
                } else {
                  if (searchBy === 'AccountSID') {
                    localString = `Contains(cast(${searchBy} , 'Edm.String'),'${list}')`;
                  } else {
                    localString = `${searchBy} in (${list})`;
                  }
                }
                if (i === 'EnrolledDate') {
                  break; //Already handled the enrolled date
                }
              }
            }
          }
          localString = `(${localString})`;
          if (localString.length > 2) {
            if (filter.length === 0) {
              filter = localString;
            } else {
              filter = filter + ` and ${localString}`;
            }
          }
        }

        if (this.props.crmData && this.props.crmData.firmRelationshipIDToken && this.props.crmData.firmRelationshipIDToken.length > 0) {
          let tokenList = '';
          for (let i in this.props.crmData.firmRelationshipIDToken) {
            if (tokenList.length === 0) {
              tokenList = `'${this.props.crmData.firmRelationshipIDToken[i]}'`;
            } else {
              tokenList = tokenList + `, '${this.props.crmData.firmRelationshipIDToken[i]}'`;
            }
          }

          if (filter.length === 0) {
            filter = filter + `RelationshipID in (${tokenList})`;
          } else {
            filter = filter + ` and RelationshipID in (${tokenList})`;
          }
        }

        if (this.props.crmData && this.props.crmData.teamIDToken && this.props.crmData.teamIDToken.length > 0) {
          let tokenList = '';
          for (let i in this.props.crmData.teamIDToken) {
            if (tokenList.length === 0) {
              tokenList = `'${this.props.crmData.teamIDToken[i]}'`;
            } else {
              tokenList = tokenList + `, '${this.props.crmData.teamIDToken[i]}'`;
            }
          }

          if (filter.length === 0) {
            filter = filter + `TeamID in (${tokenList})`;
          } else {
            filter = filter + ` and TeamID in (${tokenList})`;
          }
        }

        let list = '';
        for (let i in searchTokens) {
          if (list.length === 0) {
            list = `'${searchTokens[i]}'`;
          } else {
            list = list + `, '${searchTokens[i]}'`;
          }
        }

        if (filter.length > 0) {
          //If account search returned tokens
          if (list.length > 0) {
            wholeString = `(AccountName in (${list}) and ${filter})`;
          }
          //If account search returned no tokens
          else if (list.length === 0 && this.state.searchText !== '') {
            wholeString = `(AccountName in ('${this.state.searchText}'))`;
          } else {
            wholeString = `(${filter})`;
          }
        } else {
          if (list.length > 0) {
            wholeString = `(AccountName in (${list}))`;
          }
          //If account search returned no tokens
          else if (list.length === 0 && this.state.searchText !== '') {
            wholeString = `(AccountName in ('${this.state.searchText}'))`;
          }
        }

        filter = wholeString;

        //Relationship ID Tokenized Filter
        list = '';
        for (let i in relationshipIdTokens) {
          if (list.length === 0) {
            list = `'${relationshipIdTokens[i]}'`;
          } else {
            list = list + `, '${relationshipIdTokens[i]}'`;
          }
        }

        if (filter.length > 0) {
          //If RelationshipId search returned tokens
          if (list.length > 0) {
            wholeString = `(RelationshipId in (${list}) and ${filter})`;
          }
          //If RelationshipId search returned no tokens
          else if (list.length === 0 && this.state.searchRelationshipIdText !== '') {
            wholeString = `(RelationshipId in ('${this.state.searchRelationshipIdText}'))`;
          } else {
            wholeString = `(${filter})`;
          }
        } else {
          if (list.length > 0) {
            wholeString = `(RelationshipId in (${list}))`;
          }
          //If account search returned no tokens
          else if (list.length === 0 && this.state.searchRelationshipIdText !== '') {
            wholeString = `(RelationshipId in ('${this.state.searchRelationshipIdText}'))`;
          }
        }
        filter = wholeString;

        // Advisor ID Tokenized Filter
        list = '';
        for (let i in advisorIdTokens) {
          if (list.length === 0) {
            list = `'${advisorIdTokens[i]}'`;
          } else {
            list = list + `, '${advisorIdTokens[i]}'`;
          }
        }
        if (filter.length > 0) {
          //If advisorId search returned tokens
          if (list.length > 0) {
            wholeString = `(AdvisorId in (${list}) and ${filter})`;
          }
          //If AdvisorId search returned no tokens
          else if (list.length === 0 && this.state.searchAdvisorIdText !== '') {
            wholeString = `(AdvisorId in ('${this.state.searchAdvisorIdText}'))`;
          } else {
            wholeString = `(${filter})`;
          }
        } else {
          if (list.length > 0) {
            wholeString = `(AdvisorId in (${list}))`;
          }
          //If account search returned no tokens
          else if (list.length === 0 && this.state.searchAdvisorIdText !== '') {
            wholeString = `(AdvisorId in ('${this.state.searchAdvisorIdText}'))`;
          }
        }
        filter = wholeString;

        // Investment ID Tokenized Filter
        list = '';
        for (let i in investmentIdTokens) {
          if (list.length === 0) {
            list = `'${investmentIdTokens[i]}'`;
          } else {
            list = list + `, '${investmentIdTokens[i]}'`;
          }
        }
        if (filter.length > 0) {
          //If investmentId search returned tokens
          if (list.length > 0) {
            wholeString = `(InvestmentId in (${list}) and ${filter})`;
          }
          //If InvestmentId search returned no tokens
          else if (list.length === 0 && this.state.searchInvestmentIdText !== '') {
            wholeString = `(InvestmentId in ('${this.state.searchInvestmentIdText}'))`;
          } else {
            wholeString = `(${filter})`;
          }
        } else {
          if (list.length > 0) {
            wholeString = `(InvestmentId in (${list}))`;
          }
          //If account search returned no tokens
          else if (list.length === 0 && this.state.searchInvestmentIdText !== '') {
            wholeString = `(InvestmentId in ('${this.state.searchInvestmentIdText}'))`;
          }
        }
        filter = wholeString;

        // Investor ID Tokenized Filter
        list = '';
        for (let i in investorIdTokens) {
          if (list.length === 0) {
            list = `'${investorIdTokens[i]}'`;
          } else {
            list = list + `, '${investorIdTokens[i]}'`;
          }
        }
        if (filter.length > 0) {
          //If investorId search returned tokens
          if (list.length > 0) {
            wholeString = `(InvestorId in (${list}) and ${filter})`;
          }
          //If InvestorId search returned no tokens
          else if (list.length === 0 && this.state.searchInvestorIdText !== '') {
            wholeString = `(InvestorId in ('${this.state.searchInvestorIdText}'))`;
          } else {
            wholeString = `(${filter})`;
          }
        } else {
          if (list.length > 0) {
            wholeString = `(InvestorId in (${list}))`;
          }
          //If account search returned no tokens
          else if (list.length === 0 && this.state.searchInvestorIdText !== '') {
            wholeString = `(InvestorId in ('${this.state.searchInvestorIdText}'))`;
          }
        }
        filter = wholeString;

        // Team ID Tokenized Filter
        list = '';
        for (let i in teamIdTokens) {
          if (list.length === 0) {
            list = `'${teamIdTokens[i]}'`;
          } else {
            list = list + `, '${teamIdTokens[i]}'`;
          }
        }
        if (filter.length > 0) {
          //If teamId search returned tokens
          if (list.length > 0) {
            wholeString = `(TeamId in (${list}) and ${filter})`;
          }
          //If TeamId search returned no tokens
          else if (list.length === 0 && this.state.searchTeamIdText !== '') {
            wholeString = `(TeamId in ('${this.state.searchTeamIdText}'))`;
          } else {
            wholeString = `(${filter})`;
          }
        } else {
          if (list.length > 0) {
            wholeString = `(TeamId in (${list}))`;
          }
          //If account search returned no tokens
          else if (list.length === 0 && this.state.searchTeamIdText !== '') {
            wholeString = `(TeamId in ('${this.state.searchTeamIdText}'))`;
          }
        }
        filter = wholeString;

        // Partner ID Tokenized Filter
        list = '';
        for (let i in partnerIdTokens) {
          if (list.length === 0) {
            list = `'${partnerIdTokens[i]}'`;
          } else {
            list = list + `, '${partnerIdTokens[i]}'`;
          }
        }
        if (filter.length > 0) {
          //If partnerId search returned tokens
          if (list.length > 0) {
            wholeString = `(PartnerId in (${list}) and ${filter})`;
          }
          //If PartnerId search returned no tokens
          else if (list.length === 0 && this.state.searchPartnerIdText !== '') {
            wholeString = `(PartnerId in ('${this.state.searchPartnerIdText}'))`;
          } else {
            wholeString = `(${filter})`;
          }
        } else {
          if (list.length > 0) {
            wholeString = `(PartnerId in (${list}))`;
          }
          //If account search returned no tokens
          else if (list.length === 0 && this.state.searchPartnerIdText !== '') {
            wholeString = `(PartnerId in ('${this.state.searchPartnerIdText}'))`;
          }
        }
        filter = wholeString;

        // Client ID Tokenized Filter
        list = '';
        for (let i in clientIdTokens) {
          if (list.length === 0) {
            list = `'${clientIdTokens[i]}'`;
          } else {
            list = list + `, '${clientIdTokens[i]}'`;
          }
        }
        if (filter.length > 0) {
          //If teamId search returned tokens
          if (list.length > 0) {
            wholeString = `(ClientId in (${list}) and ${filter})`;
          }
          //If ClientId search returned no tokens
          else if (list.length === 0 && this.state.searchClientIdText !== '') {
            wholeString = `(ClientId in ('${this.state.searchClientIdText}'))`;
          } else {
            wholeString = `(${filter})`;
          }
        } else {
          if (list.length > 0) {
            wholeString = `(ClientId in (${list}))`;
          }
          //If account search returned no tokens
          else if (list.length === 0 && this.state.searchClientIdText !== '') {
            wholeString = `(ClientId in ('${this.state.searchClientIdText}'))`;
          }
        }
        filter = wholeString;

        // Custom1 Tokenized Filter
        list = '';
        for (let i in custom1Tokens) {
          if (list.length === 0) {
            list = `'${custom1Tokens[i]}'`;
          } else {
            list = list + `, '${custom1Tokens[i]}'`;
          }
        }
        if (filter.length > 0) {
          //If custom1 search returned tokens
          if (list.length > 0) {
            wholeString = `(Custom1 in (${list}) and ${filter})`;
          }
          //If Custom1 search returned no tokens
          else if (list.length === 0 && this.state.searchCustom1Text !== '') {
            wholeString = `(Custom1 in ('${this.state.searchCustom1Text}'))`;
          } else {
            wholeString = `(${filter})`;
          }
        } else {
          if (list.length > 0) {
            wholeString = `(Custom1 in (${list}))`;
          }
          //If account search returned no tokens
          else if (list.length === 0 && this.state.searchCustom1Text !== '') {
            wholeString = `(Custom1 in ('${this.state.searchCustom1Text}'))`;
          }
        }
        filter = wholeString;

        // Custom2 Tokenized Filter
        list = '';
        for (let i in custom2Tokens) {
          if (list.length === 0) {
            list = `'${custom2Tokens[i]}'`;
          } else {
            list = list + `, '${custom2Tokens[i]}'`;
          }
        }
        if (filter.length > 0) {
          //If custom2 search returned tokens
          if (list.length > 0) {
            wholeString = `(Custom2 in (${list}) and ${filter})`;
          }
          //If Custom2 search returned no tokens
          else if (list.length === 0 && this.state.searchCustom2Text !== '') {
            wholeString = `(Custom2 in ('${this.state.searchCustom2Text}'))`;
          } else {
            wholeString = `(${filter})`;
          }
        } else {
          if (list.length > 0) {
            wholeString = `(Custom2 in (${list}))`;
          }
          //If account search returned no tokens
          else if (list.length === 0 && this.state.searchCustom2Text !== '') {
            wholeString = `(Custom2 in ('${this.state.searchCustom2Text}'))`;
          }
        }
        filter = wholeString;

        // Custom3 Tokenized Filter
        list = '';
        for (let i in custom3Tokens) {
          if (list.length === 0) {
            list = `'${custom3Tokens[i]}'`;
          } else {
            list = list + `, '${custom3Tokens[i]}'`;
          }
        }
        if (filter.length > 0) {
          //If custom3 search returned tokens
          if (list.length > 0) {
            wholeString = `(Custom3 in (${list}) and ${filter})`;
          }
          //If Custom3 search returned no tokens
          else if (list.length === 0 && this.state.searchCustom3Text !== '') {
            wholeString = `(Custom3 in ('${this.state.searchCustom3Text}'))`;
          } else {
            wholeString = `(${filter})`;
          }
        } else {
          if (list.length > 0) {
            wholeString = `(Custom3 in (${list}))`;
          }
          //If account search returned no tokens
          else if (list.length === 0 && this.state.searchCustom3Text !== '') {
            wholeString = `(Custom3 in ('${this.state.searchCustom3Text}'))`;
          }
        }

        // Custom4 Tokenized Filter
        list = '';
        for (let i in custom4Tokens) {
          if (list.length === 0) {
            list = `'${custom4Tokens[i]}'`;
          } else {
            list = list + `, '${custom4Tokens[i]}'`;
          }
        }
        if (filter.length > 0) {
          //If custom4 search returned tokens
          if (list.length > 0) {
            wholeString = `(Custom4 in (${list}) and ${filter})`;
          }
          //If Custom4 search returned no tokens
          else if (list.length === 0 && this.state.searchCustom4Text !== '') {
            wholeString = `(Custom4 in ('${this.state.searchCustom4Text}'))`;
          } else {
            wholeString = `(${filter})`;
          }
        } else {
          if (list.length > 0) {
            wholeString = `(Custom4 in (${list}))`;
          }
          //If account search returned no tokens
          else if (list.length === 0 && this.state.searchCustom4Text !== '') {
            wholeString = `(Custom4 in ('${this.state.searchCustom4Text}'))`;
          }
        }
        // &$filter=
        filter = wholeString ? `&$filter=${wholeString}` : '';

        this.setState({ quickFilterModalOpen: false, filter, loading: true, page: 0 }, () => {
          this.searchAccount();
        });

        let tmpQuickfilters = Object.assign({}, quickfilters);
        let tmpQuickfilterValues = Object.assign({}, quickfilterValues);
        delete tmpQuickfilterValues.AccountID;
        this.setState({ quickfilterModalValues: tmpQuickfilterValues, quickfilterValues: tmpQuickfilterValues });
        // delete tmpQuickfilterValues.Account;

        document.cookie = `accounts_filter=${JSON.stringify(tmpQuickfilterValues)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_filter_accountid=${JSON.stringify(this.state.accountFilter)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_filter_exact=${JSON.stringify(this.state.exactAccountName)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_filter_selected=${JSON.stringify(tmpQuickfilters)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_enrollment_date_selected=${this.state.dateSaved};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchText=${JSON.stringify(this.state.searchText)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchRelationshipIdText=${JSON.stringify(this.state.searchRelationshipIdText)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchAdvisorIdText=${JSON.stringify(this.state.searchAdvisorIdText)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchInvestmentIdText=${JSON.stringify(this.state.searchInvestmentIdText)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchPartnerIdText=${JSON.stringify(this.state.searchPartnerIdText)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchClientIdText=${JSON.stringify(this.state.searchClientIdText)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchTeamIdText=${JSON.stringify(this.state.searchTeamIdText)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchInvestorIdText=${JSON.stringify(this.state.searchInvestorIdText)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchCustom1Text=${JSON.stringify(this.state.searchCustom1Text)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchCustom2Text=${JSON.stringify(this.state.searchCustom2Text)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchCustom3Text=${JSON.stringify(this.state.searchCustom3Text)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchCustom4Text=${JSON.stringify(this.state.searchCustom4Text)};path=/;${globals.SAMESITECOOKIE}`;
        document.cookie = `accounts_searchAccountSIDText=${JSON.stringify(this.state.searchAccountSIDText)};path=/;${globals.SAMESITECOOKIE}`;
      }
    );
  };

  async getAccountToken() {
    let { user, services } = this.props;
    if (this.state.accountFilter === '') {
      this.handleQuickfilterCheckboxSelect('Account', 'clear');
    } else {
      this.handleQuickfilterCheckboxSelect('Account');
      let body = {
        Registry: [
          {
            Value: `Equals('${this.state.accountFilter}')` /*this.state.exactAccountName ? `Equals('${this.state.accountFilter}')` : `Contains('${this.state.accountFilter}')`*/,
            Context: null,
            Remote: 0,
            ProcessId: 1,
          },
        ],
      };
      let response = await AccountModels.getAccountToken({
        user,
        services,
        body,
      });
      if (response && !response.error) {
        if (response.body.Registry && response.body.Registry[0].Code && response.body.Registry[0].Code == 404) {
          this.setState({ accountTokenSearch: [{ Token: 'false' }] }, () => {
            this.handleSaveQuickfilterClick();
          });
        } else {
          let tmpTokenSearch = Object.assign({}, response.body.Registry);
          document.cookie = `accounts_token_search=${JSON.stringify(tmpTokenSearch)};path=/;${globals.SAMESITECOOKIE}`;
          this.setState({ accountTokenSearch: response.body.Registry }, () => {
            this.handleSaveQuickfilterClick();
          });
        }
      }
    }
  }

  closeModal = () => {
    //If close qfmodal, set modalvalues back to values
    let { quickfilterOptions } = this.state;
    let quickfilterModalValues = Object.assign({}, this.state.quickfilterValues);
    quickfilterModalValues.EnrolledDate = this.state.quickfilterValues.EnrolledDate[0]
      ? [this.state.quickfilterValues.EnrolledDate[0], moment(new Date()).format().substring(0, 10)]
      : [moment(new Date()), moment(new Date())];
    quickfilterModalValues.EnrolledDate =
      this.state.quickfilterValues.EnrolledDate[0] && this.state.quickfilterValues.EnrolledDate[1]
        ? [this.state.quickfilterValues.EnrolledDate[0], this.state.quickfilterValues.EnrolledDate[1]]
        : [moment(new Date()), moment(new Date())];

    this.setState({
      selectedQuickfilter: null,
      quickFilterModalOpen: false,
      quickfilterModalValues,
      previewModalOpen: false,
      viewAccountDetailsModalOpen: false,
      showSearchLengthNote: false,
      dateWarning: false,
    });
  };

  async getAkoyaAccountGridPII(cellInfo) {
    let { user, services } = this.props;
    let PIIClicked = Object.assign([], this.state.PIIClicked);
    PIIClicked.push(cellInfo.index);
    this.setState({ PIIClicked, loading: true });
    let index = cellInfo.index;
    let { data } = this.state;
    let tempData = Object.assign([], data);
    if (typeof data[index]['AccountName-token'] === 'string' && data[index]['AccountName-token'].startsWith('@!!!@')) {
      let accounts = await AccountModels.getAkoyaAccountGridPII({
        user,
        services,
        accountId: data[index]['AccountID'],
      });
      if (accounts && accounts[0]) {
        tempData[index]['AccountName'] = accounts[0].AccountName;
        tempData[index]['AccountTitle'] = accounts[0].AccountTitle;
        tempData[index]['AdvisorName'] = accounts[0].AdvisorName;
        tempData[index]['ClientName'] = accounts[0].ClientName;
        tempData[index]['RelationshipID'] = accounts[0].RelationshipID;
        tempData[index]['AdvisorID'] = accounts[0].AdvisorID;
        tempData[index]['PartnerID'] = accounts[0].PartnerID;
        tempData[index]['TeamID'] = accounts[0].TeamID;
        tempData[index]['ClientID'] = accounts[0].ClientID;
        tempData[index]['InvestmentID'] = accounts[0].InvestmentID;
        tempData[index]['InvestorID'] = accounts[0].InvestorID;
        tempData[index]['Custom1'] = accounts[0].Custom1;
        tempData[index]['Custom2'] = accounts[0].Custom2;
        tempData[index]['Custom3'] = accounts[0].Custom3;
        tempData[index]['Custom4'] = accounts[0].Custom4;
        this.setState({ data: tempData });
      }
      this.setState({ loading: false });
    }
  }

  getCurrentLOA = (id, index) => {
    this.setState({ previewModalOpen: true, selectedIndex: index });
  };

  handleAccountSelect = (pk, val) => {
    this.setState({ selectedAccountId: pk, selectedAccountData: val }, () => {
      this.onEnrollClick(pk, val);
    });
  };

  handleTokenIdSave = async (field) => {
    if (!field) return;

    //Handle reading and filtering by RelationshipId, AdvisorId, InvestmentId, InvestorId, TeamId, ClientId, PartnerId tokens
    const { user, services } = this.props;

    let searchText = '';

    searchText = this.state[`search${field}Text`]; // if(field === 'RelationshipId' && searchRelationshipIdText) searchText = searchRelationshipIdText;

    this.setState({ page: 0 }, async () => {
      let tokens = [];
      //If token, make call filtered on tokens
      if (isToken(searchText)) {
        tokens = [searchText];
      } else if (searchText) {
        //If not token, get tokens, make call filtered on tokens
        let body = {
          Registry: [
            {
              Value: `Equals('${searchText}')`,
              Context: null,
              Remote: 0,
              ProcessId: 1,
            },
          ],
        };
        tokens = await tokenModels.getTokens({ user, services, body });
      }

      let temp = Object.assign([], this.state.quickfilterModalValues[field]);

      temp = searchText && [searchText];

      this.setState(
        {
          selectedQuickfilter: field,
          [`search${field}Text`]: searchText,
          [`${field.charAt(0).toLowerCase() + field.slice(1)}Tokens`]: tokens, // convert 'RelationshipId' to 'relationshipId'
          quickfilterModalValues: { ...this.state.quickfilterModalValues, [field]: temp },
        },
        () => {
          this.handleSaveQuickfilterClick();
        }
      );
    });
  };
  handleIdSave = async (field) => {
    const { searchAccountSIDText } = this.state;

    let searchText = field === 'AccountSID' && searchAccountSIDText ? parseInt(searchAccountSIDText) : null;
    this.setState({ page: 0 }, async () => {
      let temp = Object.assign([], this.state.quickfilterModalValues[field]);

      temp = searchText ? [searchText] : [];

      this.setState(
        {
          selectedQuickfilter: field,
          quickfilterModalValues: { ...this.state.quickfilterModalValues, [field]: temp },
        },
        () => {
          this.handleSaveQuickfilterClick();
        }
      );
    });
  };

  onSearchTextChange = async (e) => {
    const { user, services } = this.props;

    this.setState({ searchText: e.target.value, selectedAccountId: null, selectedAccountData: null, page: 0 }, async () => {
      const { searchText } = this.state;
      let tokens = [];
      //If token, make call filtered on tokens
      if (isToken(searchText)) {
        tokens = [searchText];
      } else if (searchText) {
        //If not token, get tokens, make call filtered on tokens
        let body = {
          Registry: [
            {
              Value: `Equals('${searchText}')`,
              Context: null,
              Remote: 0,
              ProcessId: 1,
            },
          ],
        };
        tokens = await tokenModels.getTokens({ user, services, body });
      }
      this.setState({ searchTokens: tokens }, () => {
        this.handleSaveQuickfilterClick();
      });
    });
  };

  searchAccount = debounce(async () => {
    let { user, services } = this.props;
    let { filter, orderby, page, pagesize, quickfilters, searchText } = this.state;
    this.setState({ loading: true });

    let { values, count } = await AccountModels.getAkoyaAccountsGrid({
      user,
      services,
      filter,
      orderby,
      page,
      pagesize,
    });
    console.log('call from here 2006');
    let tempData = values.map((acc) => {

      var approvedDateString = acc.ApprovedDate ? getCurrentTimeString(new Date(acc.ApprovedDate)) : null;

      return {
        ExternalAccountID: acc.ExternalAccountID,
        STATUS: acc.STATUS,
        signerName: acc.AKOYA_REQUEST.CONTACT_DETAIL.FirstName + ' ' + acc.AKOYA_REQUEST.CONTACT_DETAIL.LastName,
        signerEmail: acc.AKOYA_REQUEST.CONTACT_DETAIL.Email,
        CustodianName: acc.AKOYA_REQUEST.CUSTODIAN.Name,
        ApprovedDate: approvedDateString,
      };
    });
    this.setState({ data: [] });

    let customNoData = !searchText && quickfilters.length === 0 && count === 0 ? true : false;
    //  let CustonianName =

    this.setState({ data: tempData, loading: false, rowCount: count, customNoData, PIIClicked: [] });
  }, 500);

  onSortedChange = (obj) => {
    let tempOrderby = '';
    let desc = obj[0].desc ? ' desc' : '';
    tempOrderby = `&$orderby=${obj[0].id}${desc}`;
    this.setState({ orderby: tempOrderby }, () => {
      this.searchAccount();
    });
  };

  changeDate = (date, index) => {
    if (!date) {
      this.setState({ dateFromSelected: false, dateToSelected: false });
    } else {
      if (index === 0) {
        this.setState({ dateFromSelected: true, dateToSelected: true });
      }
      if (index === 1) {
        this.setState({ dateToSelected: true });
      }
    }

    let temp = Object.assign([], this.state.quickfilterModalValues[this.state.selectedQuickfilter]);
    let val = date ? moment(date) : moment(new Date());
    if (!date) {
      temp[0] = moment(new Date());
      temp[1] = moment(new Date());
    } else {
      temp[index] = moment(new Date(val));
    }

    // this.setState({quickfilterModalValues: {...this.state.quickfilterModalValues, [this.state.selectedQuickfilter]: temp}});

    this.setState({ quickfilterModalValues: { ...this.state.quickfilterModalValues, [this.state.selectedQuickfilter]: temp } }, () => {
      let checkValid = this.checkValidDate();
      if (checkValid === '') {
        this.setState({ dateWarning: false });
      } else {
        this.setState({ dateWarning: true });
      }
    });
  };

  onEnrollClick = (pk, val) => {
    this.props.setSelectedAccount(pk, val);
    this.setState({ accountID: pk }, () => {
      this.setState({ toEnroll: true });
    });
  };

  onPageSizeChange = (size) => {
    document.cookie = `table_pagesize=${JSON.stringify(size)};path=/;${globals.SAMESITECOOKIE}`;
    this.setState({ pagesize: size, page: 0, loading: true }, () => {
      this.searchAccount();
    });
  };

  onPageChange = (index) => {
    this.setState({ page: index, loading: true }, () => {
      this.searchAccount();
    });
  };

  async fetchAccountData(accountId, cellInfo) {
    const { enrollAccountTaxEntityShow } = this.state;
    let showData = this.state.PIIClicked.includes(cellInfo.index) ? true : false;
    let { user, services } = this.props;
    this.setState({ viewAccountDetailsModalOpen: true, previewLoading: true });
    let accounts = await AccountModels.getAccountData({
      user,
      services,
      accountId,
      showData,
    });
    // this.setAccountData(accounts[0]);
    // For TaxEntityID and TaxEntityName we need to detokenize RelationshipID and get the Tax Entity Record
    // detokenizeField(idField, nameField, token, accounts, user, services)
    if (accounts[0]) {
      if (accounts[0]['RelationshipID-token']) {
        detokenizeField('TaxEntityID', 'TaxEntityName', accounts[0]['RelationshipID-token'], accounts[0], enrollAccountTaxEntityShow, user, services).then((accounts) => {
          this.setAccountData(accounts);
        });
      } else {
        accounts[0]['TaxEntityID'] = accounts[0]['RelationshipID'];
        detokenizeField('TaxEntityID', 'TaxEntityName', '', accounts[0], enrollAccountTaxEntityShow, user, services).then((accounts) => {
          this.setAccountData(accounts);
        });
      }
    }
  }

  async setAccountData(data) {
    let { user, services } = this.props;
    let temp = {};
    temp.AccountID = data.AccountID;
    temp.AccountName = data.AccountName;
    temp['AccountName-token'] = data['AccountName-token'];
    temp.AccountTitle = data.AccountTitle;
    temp.AccountTypeID = { label: '', value: data.AccountTypeID };
    temp.ChangeDate = data.ChangeDate;
    temp.TaxEntityName = data.TaxEntityName ? data.TaxEntityName : '';
    temp.TaxEntityID = data.TaxEntityID ? data.TaxEntityID : '';
    temp.CustodianName = data.CUSTODIAN.Name;
    temp.CustodianID = data.CustodianID;
    temp.CustodianData = data.CUSTODIAN;
    temp.CustodianContact = data.CONTACT
      ? {
          label: data.CONTACT.NameAlias,
          value: data.CONTACT.ContactID,
          DetailID: data.CONTACT.CONTACT_DETAIL[0] ? data.CONTACT.CONTACT_DETAIL[0].DetailID : null,
          data: data.CONTACT.CONTACT_DETAIL[0] ? data.CONTACT.CONTACT_DETAIL[0] : null,
        }
      : null;
    temp.ContactID = data.ContactID;
    temp.ContactDetailID = data.ContactDetailID;
    temp.Currency1 = data.Currency1;
    temp.Fund = { label: '', value: data.Fund_UID };
    temp.Fund_UID = data.Fund_UID;
    temp.InvestorName = data.InvestorName;
    temp.InvestorID = data.InvestorID;
    temp.Status = data.AccountStatus;
    temp.StatusName = data.ACCOUNT_LOA_STATUS.Name;
    temp.AdvisorID = data.AdvisorID;
    temp.RelationshipID = data.RelationshipID;
    temp.ManagerType = data.ManagerType;
    temp.LOA = data.LOA;
    temp.AdvisorName = data.AdvisorName;
    temp.ClientName = data.ClientName;
    temp.Account_PartnerID = data.Account_PartnerID;
    temp.Account_TeamID = data.Account_TeamID;
    temp.Account_ClientID = data.Account_ClientID;
    temp.Account_InvestmentID = data.Account_InvestmentID;
    temp.Account_Custom1 = data.Account_Custom1;
    temp.Account_Custom2 = data.Account_Custom2;
    temp.Account_Custom3 = data.Account_Custom3;
    temp.Account_Custom4 = data.Account_Custom4;
    temp.Account_Related_Entity_UID = data.Account_Related_Entity_UID ? data.Account_Related_Entity_UID : '';

    // for(let i in data.ACCOUNT_SIGNER_LINK) {
    //   if(data.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL && data.ACCOUNT_SIGNER_LINK[i].ChangeType !== 'D' ) {
    //     data.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.FullName = data.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.FirstName + ' ' + data.ACCOUNT_SIGNER_LINK[i].CONTACT_DETAIL.LastName;
    //   }
    // }
    // temp.ACCOUNT_SIGNER_LINK = data.ACCOUNT_SIGNER_LINK

    // REMOVE SOFT DELETES FROM SIGNERS
    let signers = [];
    let validSigners = [];

    for (let i in data.ACCOUNT_SIGNER_LINK) {
      if (data.ACCOUNT_SIGNER_LINK[i].ChangeType !== 'D') {
        //Push to Signers
        signers.push(data.ACCOUNT_SIGNER_LINK[i]);
      }
    }
    for (let i in signers) {
      if (signers[i].CONTACT_DETAIL) {
        //Create Full Name
        signers[i].CONTACT_DETAIL.FullName = signers[i].CONTACT_DETAIL.FirstName + ' ' + signers[i].CONTACT_DETAIL.LastName;
      }
      validSigners.push(parseInt(i));
    }

    this.setValidSigners(validSigners);
    temp.ACCOUNT_SIGNER_LINK = signers;

    let custodianRelated = {};
    if (temp.Account_Related_Entity_UID) {
      custodianRelated = await AccountModels.getCustodianRelated({
        user,
        services,
        id: temp.Account_Related_Entity_UID,
      });
      if (custodianRelated[0]) {
        temp.CustodianRelatedName = custodianRelated[0].Name;
      }
    }

    this.setState({
      previewAccountDetailsData: temp,
      previewLoading: false,
    });
    console.log('hello data', data.AKOYA_REQUEST.CUSTODIAN.Name, 'asdaskdj', data);
  }

  setValidSigners = (arr) => {
    this.setState({ validSigners: arr });
  };

  updateAccountData = (obj) => {
    return new Promise((resolve, reject) => {
      this.setState({ previewAccountDetailsData: obj });
      resolve();
    });
  };

  onViewAuthorizationsClick = () => {
    this.setState({ toAuthorizations: true });
  };

  onViewAkoyaAccountClick = () => {
    this.setState({ toAkoyaAccount: true });
  };

  onBatchJobsClick = () => {
    this.setState({ toBatchJobs: true });
  };

  onViewAccountsClick = () => {
    this.setState({ toMyEnrollments: true });
  };

  checkValidDate = () => {
    let { quickfilterModalValues } = this.state;
    let date1 = new Date(quickfilterModalValues.EnrolledDate[0]);
    let date2 = new Date(quickfilterModalValues.EnrolledDate[1]);
    if (date1.setHours(0, 0, 0, 0) > date2.setHours(0, 0, 0, 0)) {
      return <div className="warning-message">Please select a valid date range.</div>;
    } else {
      return '';
    }
  };

  getModalStyle = () => {
    let { selectedQuickfilter } = this.state;
    if (selectedQuickfilter === 'Custodian') return largeQuickfilterModalStyle;
    else return dateQuickfilterModalStyle;
  };
  clearAccountQuickFilter = () => {
    let quickfiltersTemp = Object.assign([], this.state.quickfilters);
    if (quickfiltersTemp.includes('Account')) {
      quickfiltersTemp.splice(quickfiltersTemp.indexOf('Account'), 1);
    }
    this.setState(
      {
        accountFilter: '',
        quickfilters: quickfiltersTemp,
        quickfilterModalValues: { ...this.state.quickfilterModalValues, Account: [] },
        quickfilterValues: { ...this.state.quickfilterValues, Account: [] },
        accountTokenSearch: [],
      },
      () => {
        this.handleSaveQuickfilterClick();
        this.closeModal();
      }
    );
  };

  clearEnrolledDateQuickFilter = () => {
    let quickfiltersTemp = Object.assign([], this.state.quickfilters);
    if (quickfiltersTemp.includes('EnrolledDate')) {
      quickfiltersTemp.splice(quickfiltersTemp.indexOf('EnrolledDate'), 1);
    }

    this.setState(
      {
        quickfilters: quickfiltersTemp,
        quickfilterModalValues: { ...this.state.quickfilterModalValues, EnrolledDate: [moment(new Date()), moment(new Date())] },
        quickfilterValues: { ...this.state.quickfilterValues, EnrolledDate: [] },
        dateSaved: false,
      },
      () => {
        this.handleSaveQuickfilterClick();
        this.closeModal();
      }
    );
  };

  handleBatchUpdateClick = () => {
    this.setState({
      batchUpdateStatusModalOpen: true,
    });
  };

  handlePages = (pages) => {
    return pages === 0 ? 1 : pages; // DEV-876 Dont let pages = 0, default to 1 or issues with jumpto occurs
  };

  handleExportPref = (obj) => {
    this.setState({ exportPreferences: obj }, () => {
      this.handleExportPrint('Export', obj);
    });
  };

  handleExportPrint = (type, preference = null) => {
    let { exportPreferences } = this.state;
    if (preference) {
      exportPreferences = preference;
    }

    if (type) {
      let policies = this.props.user.firmPolicies;
      let messageDefault = globals.EXPORT_WARNING_MESSAGE ? globals.EXPORT_WARNING_MESSAGE : 'Please do not navigate away from this page before it finishes.';
      var message = `The export process will run in the background.  ${messageDefault}`;
      confirmAlert({
        title: `${type}`,
        childrenElement: () => <div>{message}</div>,
        buttons: [
          {
            label: <div id="cancel-confirm-button">Cancel</div>,
            onClick: () => {},
          },
          {
            label: <div id="continue-confirm-button">Continue</div>,
            onClick: () => {
              this.exportGrid(exportPreferences);
              this.setState({ export_pref_open: false });
            },
          },
        ],
      });
    }
  };
  closeExportPreferences = () => {
    this.setState({ export_pref_open: false });
  };
  exportGrid = async (exportPreferences = null) => {
    // searchGeneric(filters, export)  export = true will return all results
    const { columns, enrollHomeTitle } = this.state;
    let columnsFiltered = [...columns.filter((x) => x.accessor !== 'Checkboxes')];
    let visibleColumns = [...columns.filter((x) => x.accessor !== 'Checkboxes')];
    let customColumns = columnsFiltered;
    this.setState({ exporting: true });
    await this.searchGenericExport('', true, exportPreferences).then((res) => {
      // When we are exporting datasets we want to apply the columns formatting to the CSV
      // Get formats for columns and create an object so we can quickly get the config when looping through extract fields
      // In counterparty, we needed to do this by adding the formatting to the columns state
      if (res) {
        let firstrecord = res[0];
        Object.keys(firstrecord).map((key) => {
          if (
            !visibleColumns.find((x) => {
              return x.accessor === key;
            })
          ) {
            customColumns.push({
              accessor: key,
              Header: key,
            });
          }
        });
      }

      // Exclude any token columns
      customColumns = [...customColumns.filter((x) => x.accessor.indexOf('-token') === -1)];

      // Grab formatting from custom columns (all columns)
      let cols = {};
      for (const col of customColumns) {
        let datatype = 'string';
        let format = '';
        if (col.datatype) {
          if (col.datatype['value']) {
            datatype = col.datatype['value'];
          }
          if (col.format['value'] && datatype !== 'string') {
            format = col.format['value'];
            cols[col.accessor] = { datatype, format };
          }
        }
      }

      // We are looping through every record and every field in those records to format the fields properly.
      // We use a filter with the map to eliminate the looping of unnecessary elements in the object.
      Object.keys(res).map((key) => {
        let record = res[key];
        Object.keys(record)
          .filter((x) => cols[x])
          .map((key2) => {
            if (cols[key2]) {
              record[key2] = formatValue(record[key2], cols[key2].datatype, cols[key2].format);
            }
          });
      });
      let fileNameCaption = 'Akoya Accounts';
      downloadCSVFormatted({
        data: res,
        fileName: fileNameCaption + ' - ' + this.newDate() + '.csv',
        exportPreferences,
        visibleColumns,
        customColumns,
      });
    });
  };

  searchGenericExport = async (filters, exportGrid, exportPreferences = null) => {
    let props = this.props;
    let { user, services, service, API, searchName, searchIsTokenized, context } = this.props;
    let { filter, orderby, page, pagesize, preferencesFilter, tokenFilter, searchTokens, searchText, columns, customColumns, visibleColumns, newGrid } = this.state;
    let originalPage = page;
    let originalPageSize = pagesize;

    // chunkSize is what is used to split up the API calls of larger datasets
    let chunkSize = globals.EXPORT_CHUNK_SIZE ? parseInt(globals.EXPORT_CHUNK_SIZE) : 999;
    let chunkNumberOfPages = 0;

    // If exportRows = all then clear out the filter, because we want all records
    if (exportPreferences) {
      if (exportPreferences.exportRows === 'all') {
        filter = '';
      }
    }

    //Get Count
    page = 0;
    pagesize = 1;

    let { count } = await AccountModels.getAkoyaAccountsGrid({
      user,
      services,
      filter,
      orderby,
      page,
      pagesize,
    });
    console.log('call from here 2513');
    if (count > 0) {
      chunkNumberOfPages = Math.ceil(count / chunkSize); // Get number of times we need to loop through API call
    }

    let chunkRead = [];
    let response = [];
    let singlePage = false;
    pagesize = chunkSize;

    // If exportRows = page then set the page and pagesize to the original page size that came in
    if (exportPreferences) {
      if (exportPreferences.exportRows === 'page') {
        page = originalPage;
        pagesize = originalPageSize;
        singlePage = true;
      }
    }

    if (singlePage) {
      // Don't chunk - just show a single page of results
      response = await AccountModels.getAkoyaAccountsGrid({
        user,
        services,
        filter,
        orderby,
        page,
        pagesize,
      });
      console.log('call from here 2542');
      if (response.values) {
        chunkRead.push(...response.values); // Gather results of call into array for final export
      }
    } else {
      for (let chunk = 0; chunk < chunkNumberOfPages; chunk++) {
        page = chunk;
        response = await AccountModels.getAkoyaAccountsGrid({
          user,
          services,
          filter,
          orderby,
          page,
          pagesize,
        });
        console.log('call from here 2557');
        if (response.values) {
          chunkRead.push(...response.values); // Gather results of call into array for final export
        }
      }
    }

    if (chunkRead && exportGrid === true) {
      this.setState({ exportData: chunkRead, exporting: false });
      return chunkRead;
    }
  };
  newDate = () => {
    var currentDate = new Date();
    var date = currentDate.getMonth() + 1 + '/' + currentDate.getDate() + '/' + currentDate.getFullYear();
    return date;
  };
  render() {
    var ie = navigator.userAgent.match(/.NET/g);
    var edge = navigator.userAgent.match(/Edge/g);
    let {
      selectedQuickfilter,
      quickfilters,
      tokenFilter,
      enrollPrefixLabel,
      enrollAccountsNewButton,
      enrollHomeTitle,
      enrollHomeSection,
      enhancedNavigation,
      customNoData,
      advisorId_label,
      advisorId_filter,
      relationshipId_label,
      relationshipId_filter,
      investorId_filter,
      investorId_label,
      clientId_filter,
      clientId_label,
      investmentId_filter,
      investmentId_label,
      partnerId_filter,
      partnerId_label,
      teamId_filter,
      teamId_label,
      custom1_label,
      custom1_filter,
      custom2_label,
      custom2_filter,
      custom3_label,
      custom3_filter,
      custom4_label,
      custom4_filter,
      exportPreferences,
      rowCount,
      enrollment_anonymized,
      columns,
    } = this.state;
    const { user, services } = this.props;
    let path = getCookie('path');
    let isHome = checkIfHome(enrollHomeSection); // We made the home icon and redirect after account creation a policy.  This tells us if we are on the home screen.
    let enrollNewButton = `${enrollPrefixLabel} Account`;

    enrollNewButton = enrollAccountsNewButton ? enrollAccountsNewButton : enrollNewButton; // Enhanced Navigation - UI.Enrollment.Enroll.Accounts.New.Button.Label
    return (
      <div className="myenrollments-wrapper">
        {this.state.toMyEnrollments ? <Redirect push to={`/myenrollments`} /> : null}
        {/* {this.state.toBatchJobs ? <Redirect push to={`/batchjobs`} /> : null} */}
        {this.state.toAuthorizations ? <Redirect push to={`/authorizations`} /> : null}
        {this.state.toAkoyaAccount ? <Redirect push to={`/akoyaaccounts`} /> : null}
        {this.state.toEnroll && this.state.accountID ? <Redirect push to={`/enroll/${this.state.accountID}`} /> : null}
        {this.state.toEnroll && !this.state.accountID ? <Redirect push to={`/enroll`} /> : null}
        {this.state.export_pref_open && (
          <ExportPreference user={user} services={services} exportPreferences={exportPreferences} close={this.closeExportPreferences} handleExportPref={this.handleExportPref} />
        )}
        <div className="header-myenrollments">
          <div className="myenrollments-title">
            <i
              className={isHome && enhancedNavigation ? 'fa fas fa-home generic-enhanced-disabled' : 'fa fas fa-home'} // Disable home icon when on home
              onClick={() => {
                if (!(isHome && enhancedNavigation)) {
                  enrollHomeSection == 'Accounts' ? this.onViewAccountsClick() : this.onViewAuthorizationsClick();
                }
              }}
            />
            {enhancedNavigation && <span className="generic-divider">|</span>}
            {'Akoya Accounts'}
            {!globals.REMOTE_INSTANCE ? (
              <i
                title="Help"
                onClick={() => {
                  linker.UniversalHelp('F.Counterparty.Account.Custom', 'popup', null);
                }}
                className="help-info-icon fa fas fa-info-circle"
              />
            ) : null}
          </div>
          <div className="generic-button-wrapper">
            {(path === 'regular' || path === 'enroll' || path === 'external') && (
              <div
                className={enrollAccountsNewButton ? 'generic-button-primary generic-button-margin-right generic-button-long' : 'generic-button-primary generic-button-margin-right'}
                id="enroll-account-button"
                onClick={() => {
                  this.onEnrollClick(null, null);
                }}
              >
                {enrollNewButton}
              </div>
            )}
            {!enrollment_anonymized && (
              <div
                id="view-authorizations-button"
                className="generic-button-secondary"
                onClick={() => {
                  this.onViewAuthorizationsClick();
                }}
              >
                Authorizations 122323
              </div>
            )}
            <div
              id="view-akoya-button"
              className="generic-button-secondary"
              onClick={() => {
                this.onViewAkoyaAccountClick();
              }}
            >
              Akoya Account
            </div>
          </div>
        </div>

        <div className="subheader-myenrollments">
          <div className="table-container">
            <ReactTable
              key={this.state.key}
              data={this.state.data}
              columns={enrollment_anonymized ? [...columns.filter((x) => !excludeCols.includes(x.accessor))] : columns}
              page={this.state.page || 0}
              pages={this.handlePages(Math.ceil(this.state.rowCount / this.state.pagesize))}
              pageSize={this.state.pagesize}
              className="-striped -highlight grid table"
              onSortedChange={this.onSortedChange} // Called when a sortable column header is clicked with the column itself and if the shiftkey was held. If the column is a pivoted column, `column` will be an array of columns
              NoDataComponent={() => {
                return (
                  !this.state.loading &&
                  (customNoData ? (
                    <div className="rt-noData-custom">
                      It looks like there aren't any accounts.
                      {/* {(path === 'regular' || path === 'enroll' || path === 'external') && (
                        <div>
                          No Data
                        </div>
                      )} */}
                    </div>
                  ) : (
                    <div className="rt-noData-custom">No rows found</div>
                  ))
                );
              }}
              onPageSizeChange={this.onPageSizeChange}
              onPageChange={this.onPageChange}
              loading={this.state.loading}
              manual={true}
              // getTrProps={this.getTrProps}
            />
            {rowCount && rowCount > 0 ? <div className="grid-total-records">{rowCount && `${rowCount.toLocaleString()} Total Records`}</div> : null}
          </div>
        </div>
      </div>
    );
  }
}
