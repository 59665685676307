import * as dataService from '../../Utils/DataService';
import * as jwt_decode from 'jwt-decode';
import { akoyaDetails } from './akoya-config';

export async function GetAkoyaRequestStatus(code) {
  let apiurl = localStorage.getItem('apiurl');
  let key = localStorage.getItem('key');
  let body = {};
  body['Token'] = code;
  let postProperties = {
    BaseUrl: `${apiurl}/akoyarequest/getstatus`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: '',
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': key,
      //'cookie-key':cookie,
    },
  };
  return await fetch(postProperties.BaseUrl, {
    method: 'POST',
    headers: postProperties.HeaderVals,
    body: JSON.stringify(body),
  });
}

export async function SaveAkoyaRequestAuth(authCode, code) {
  let body = {};
  body['RequestToken'] = code;
  body['authCode'] = authCode;
  let apiurl = localStorage.getItem('apiurl');
  let key = localStorage.getItem('key');
  let postProperties = {
    BaseUrl: `${apiurl}/akoyarequest/saveauth`,
    Method: 'POST',
    SuccessMessage: 'Request Successful',
    FailureMessage: 'Request Failed',
    SuppressMessageOverride: false,
    ShowMessage: '',
    CallBody: body,
    HeaderVals: {
      'Content-Type': 'application/json',
      'Ocp-Apim-Subscription-Key': key,
    },
  };
  return await fetch(postProperties.BaseUrl, {
    method: 'POST',
    headers: postProperties.HeaderVals,
    body: JSON.stringify(body),
  });
}

export async function fetchStreamData(authCode) {
  const requestBody = new URLSearchParams();
  requestBody.append('grant_type', 'authorization_code');
  requestBody.append('code', authCode);
  requestBody.append('redirect_uri', akoyaDetails.redirectUrl);

  const credentials = btoa(`${akoyaDetails.clientId}:${akoyaDetails.clientSecret}`);
  return await fetch(akoyaDetails.tokenUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
      Authorization: `Basic ${credentials}`,
    },
    body: requestBody.toString(),
  });
}

function decodeToken(token) {
  try {
    const decoded = jwt_decode.default(token);
    console.log('Decoded Token:', decoded);
    return decoded;
  } catch (error) {
    console.error('Invalid token', error);
    return null;
  }
}
