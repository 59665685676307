// Token url will be appended with proxy setting in package.json file.
export const akoyaDetails = Object.freeze({
  authUrl: "https://sandbox-idp.ddp.akoya.com/auth",
  clientId: "e5f09e2a-21a1-4626-971b-a525a6712565",
  clientSecret: "CtqY.-fbv6opPVha0DZEF7m8IK",
  redirectUrl: "https://devenrollment.totalwealthviews.net/callback",
  tokenUrl: "https://sandbox-idp.ddp.akoya.com/token",
  grant_type: "authorization_code",
  accountsInfoUrl: "/akoya-products/accounts-info/v2/mikomo",
  balancesUrl: "/akoya-products/balances/v2/mikomo",
  investmentsUrl: "/akoya-products/accounts/v2/mikomo",
  paymentsUrl:
    "/akoya-products/payments/v2/mikomo/{account-id}/payment-networks",
  customersUrl: "/akoya-products/customers/v2/mikomo/current",
  contactsUrl: "/akoya-products/contacts/v2/mikomo/{account-id}",
  statementsUrl:
    "/akoya-products/statements/v2/mikomo/{account-id}?startTime=2020-02-26T00:00:00Z&endTime=2024-09-26T00:00:00Z&offset=0&limit=5",
});
