import React, { Component } from 'react';
import { akoyaDetails } from './akoya-config';
import Modal from 'react-modal';
import * as AkoyaRequestModel from './AkoyaRequestModel';

const Spinner = require('react-spinkit');

let spinnerModalStyle = {
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: '1111',
    background: 'rgba(0, 0, 0, 0.2)',
  },
  content: {
    top: '8%',
    left: '12%',
    height: '80%',
    width: '75%',
    border: 'none',
    background: 'transparent',
  },
};

const styles = {
  body: {
    fontFamily: 'Arial, sans-serif',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    margin: 0,
    backgroundColor: '#fff',
    color: '#333',
    textAlign: 'center',
  },
  card: {
    padding: '30px',
    backgroundColor: '#eef5f6',
    borderRadius: '15px',
    boxShadow: '0 4px 10px rgb(0 0 0 / 17%)',
    width: '80%',
    maxWidth: '500px',
  },
  checkmark: {
    width: '80px',
    height: '80px',
    marginBottom: '20px',
  },
  successMessage: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#2c3e50',
    lineHeight: '33px',
  },
  subMessage: {
    fontSize: '18px',
    marginTop: '10px',
    color: '#7f8c8d',
    opacity: 0.8,
  },
};

export default class AkoyaRequestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestToken: null,
      data: null,
      accountInfo: null,
      token: null,
      isLoader: true,
      isAuthorized: false,
      apirul: null,
      key: null,
    };
  }

  componentDidMount() {
    // Call your API here
    const urlParams = new URLSearchParams(window.location.search);
    let code = urlParams.get('code');
    const iscomplete = urlParams.get('iscomplete');
    if (!iscomplete) {
      let apiurl = urlParams.get('apiurl');
      localStorage.setItem('apiurl', apiurl);
      let key = urlParams.get('key');
      localStorage.setItem('key', key);
    }
    if (code) {
      code = code.replace(/ /g, '+');
      this.setState({ requestID: code });
      localStorage.setItem('request-token', code);
      this.GetAkoyaRequestStatus(code, iscomplete);
    }
  }

  GetAkoyaRequestStatus = async (code, iscomplete) => {
    this.setState({ isLoader: true });
    let response = await AkoyaRequestModel.GetAkoyaRequestStatus(code);
    if (response.ok) {
      if (response.status === 202) {
        this.setState({ isAuthorized: false });
        this.obtainAuthorizationCodeHandler();
      } else {
        this.setState({ isLoader: false });
        this.setState({ isAuthorized: true, iscomplete: iscomplete });
      }
    } else {
      this.setState({ isLoader: false });
      if (response.status === 408) {
        this.setState({ isExpired: true });
      }
    }
  };

  obtainAuthorizationCodeHandler = () => {
    console.log('Home => Obtain Authroization Code handler called');
    if (akoyaDetails) {
      window.location.href =
        akoyaDetails.authUrl +
        '?connector=mikomo&client_id=' +
        akoyaDetails.clientId +
        '&redirect_uri=' +
        akoyaDetails.redirectUrl +
        '&response_type=code&scope=openid&offline_access&state=appstate';
    }
  };

  render() {
    const { isLoader, isAuthorized, iscomplete, isExpired } = this.state;
    return (
      <div className="content">
        <div className="sub-content">
          {!isLoader && (
            <>
              {/* <div className="logout-wrapper">
                <div className="logout"> */}
              {/* <div className="circle">
                  <FontAwesome name="permissiondenied" className="fa-lock align-lock" size="5x" />
                </div> */}
              {/* <div className="center-align">
                    <div className="logout-message">{iscomplete ? `Your request has been successfully processed` : 'Your request is already processed'} .</div>
                    <h4 className="thank-message">Thank you for using our website</h4>
                  </div>
                </div>
              </div> */}
              <div style={styles.body}>
                <div style={styles.card}>
                  {/* Green Checkmark inside filled circle SVG */}
                  {isAuthorized && (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="100" height="100" style={styles.checkmark}>
                      {/* Circle background */}
                      <circle cx="12" cy="12" r="10" fill="#27ae60" />
                      {/* Checkmark */}
                      <path fill="none" stroke="#fff" strokeWidth="2" d="M5 12l5 5l09 -10" />
                    </svg>
                  )}

                  {isExpired && (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48" height="48">
                      <circle cx="24" cy="24" r="20" fill="red" />
                      <line x1="24" y1="12" x2="24" y2="24" stroke="white" stroke-width="3" />
                      <line x1="24" y1="30" x2="24" y2="36" stroke="white" stroke-width="3" />
                    </svg>
                  )}

                  <div style={styles.successMessage}>
                    Akoya Authorization
                    <br />
                    {isAuthorized && `Successfully Completed!`}
                    {isExpired && `Link has expired.`}
                  </div>
                  <div style={styles.subMessage}>
                    {isAuthorized && `Your Akoya account is now fully authorized and ready to use.`} {isExpired && `Please contact the administrator.`}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {isLoader && (
          <Modal isOpen={isLoader} style={spinnerModalStyle}>
            <center>
              <Spinner id="view-spinner" name="line-scale-pulse-out-rapid" color="#315B7B" />
            </center>
          </Modal>
        )}
      </div>
    );
  }
}
