import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as AkoyaRequestModel from './AkoyaRequestModel';
import { isError } from 'lodash';

const styles = {
  body: {
    fontFamily: 'Arial, sans-serif',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    margin: 0,
    textAlign: 'center',
    overflow: 'hidden',
  },
  messageContainer: {
    position: 'relative',
  },
  loadingMessage: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#000',
    marginBottom: '20px',
    animation: 'fadeInOut 3s infinite',
  },
  spinner: {
    border: '3px solid #33b4d6',
    borderTop: '3px solid transparent',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    animation: 'spin 1.5s linear infinite',
    margin: '0 auto',
  },
};

class Callback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
    };
  }

  componentDidMount() {
    console.log('Callback => componentDidMount called');

    // The code is in the URL query string
    const queryParams = new URLSearchParams(window.location.search);
    const authCode = queryParams.get('code');

    if (authCode) {
      // Store the token (you can also use context or Redux)
      localStorage.setItem('auth_code', authCode);
      this.fetchStreamData(authCode);
    } else {
      this.setState({ isError: true });
    }
  }

  fetchStreamData = async (authCode) => {
    try {
      let requestToken = localStorage.getItem('request-token');
      const response = await AkoyaRequestModel.SaveAkoyaRequestAuth(authCode, requestToken);
      this.setState({ isError: false });
      if (response.status === 200) {
        this.props.history.push('/akoyarequest?code=' + requestToken + '&iscomplete=true');
      }
      else{
        this.setState({ isError: true });
      }
    } catch (error) {
      this.setState({ isError: true });
    }
  };

  render() {
    const {isError} = this.state
    return (
      <div style={styles.body}>
        <style>
          {`
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
        </style>
        <div style={styles.messageContainer}>
          <div style={styles.loadingMessage}>
            {!isError && `Completing your request...`} {isError && `Something went wrong. Please contact the administrator.`}
          </div>
          {!isError && <div style={styles.spinner}></div>}
        </div>
      </div>
    );
  }
}

export default withRouter(Callback);
